import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
//import Text from 'common/src/components/Text';
//import Heading from 'common/src/components/Heading';
//import Button from 'common/src/components/Button';
//import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import Particles from '../../Agency/Particle';
//import BannerWrapper, { DiscountLabel } from './bannerSection.style';
import BannerWrapper, { ContactForm } from './bannerSection.style';

const BannerSection = ({
  row,
  col,
  title,
  btnStyle,
  description,
  discountText,
  discountAmount,
  outlineBtnStyle,
}) => {
  {/*const ButtonGroup = () => (
    <Fragment>
      <Button title="GET STARTED" {...btnStyle} />
       <Button
        title="WATCH WORKS"
        variant="textButton"
        icon={<i className="flaticon-next" />}
        {...outlineBtnStyle}
      /> 
    </Fragment>
  );*/}
  const handleFormSubmission = (e) => {
    e.preventDefault();

    let contactForm = document.getElementById("contact-form");
    let formData = new FormData(contactForm);
    formData.append("form-name", "contact");

    if (formData.get("name").length < 1) {
      alert("Name field is required.");
      return;
    }
    
    if (formData.get("company").length < 1) {
      alert("Company name is required.");
      return;
    }

    if (formData.get("email").length < 1 || formData.get("email").indexOf("@") < 0 || formData.get("email").indexOf(".") < 0) {
      alert("Valid email is required.");
      return;
    }

    if (formData.get("phone").length < 10) {
      alert("Phone number is required.");
      return;
    }

    if (formData.get("state").length < 1) {
      alert("State is required.");
      return;
    }

    if (formData.get("isAdvisor").length < 1) {
      alert("Please confirm if you are an advisor or consultant.");
      return;
    }




    fetch('/', {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString()
    }).then(() => {
      window.location.href = "https://www.archerhealth.com/contact-us-success";
    }).catch((error) => {
      //console.log(error);
      alert("There was an error submitting your contact information. Please try again, or give us a call.");
    });
  }
  return (
    <BannerWrapper>
      <Particles />
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            {/* <DiscountLabel>
              <Text content="25% Discount" {...discountAmount} />
              <Text content="on every first project budget" {...discountText} />
            </DiscountLabel> */}
            <h1 style={{fontSize: "65px", fontWeight: "500", margin: "0"}}><span style={{color: "#19B8E8"}}>Contact</span> <span style={{color: "#3D636C"}}>Us</span></h1>
 <ContactForm>
            <form name="contact" data-netlify="true" id="contact-form">
              <input type="hidden" name="form-name" value="contact" />
              <p><label>Name<span className="required">*</span><br />
              <input type="text" name="name" required /></label></p>

              <p><label>Title<br />
              <input type="text" name="title" /></label></p>

              <p><label>Company<span className="required">*</span><br />
              <input type="text" required name="company" /></label></p>

              <p><label>Email<span className="required">*</span><br />
              <input type="email" required name="email"/></label></p>

              <p><label>Phone<span className="required">*</span><br />
              <input type="text" required name="phone"/></label></p>

              <p><label>State<span className="required">*</span><br />
              <select required name="state">
                <option value="">- Select -</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
              </select></label></p>

              <p><label>Message<br />
              <input type="text" name="message" /></label></p>

              <p><label>Are you an Advisor/Consultant?<span className="required">*</span><br />
              <select name="isAdvisor">
                <option value="">- Select -</option>
                <option value="yes">Yes</option>
                <option value="no">No</option></select></label></p>

              <button onClick={(e) => handleFormSubmission(e)}>Submit</button>
            </form>
            </ContactForm>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  title: PropTypes.object,
  btnStyle: PropTypes.object,
  description: PropTypes.object,
  contentStyle: PropTypes.object,
  discountText: PropTypes.object,
  discountAmount: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '60%', '50%'],
  },
  title: {
    fontSize: ['16px', '24px', '32px', '45px'],
    fontWeight: '300',
    color: '#3D636C',
    //letterSpacing: '-0.025em',
    letterSpacing: 0,
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  description: {
    fontSize: ['14px','16px','20px'],
    color: '#3D636C',
    lineHeight: '1.5',
    mb: '0',
  },
  btnStyle: {
    minWidth: ['120px', '156px'],
    fontSize: '14px',
    fontWeight: '300',
  },
  outlineBtnStyle: {
    minWidth: ['130px', '156px'],
    fontSize: '14px',
    fontWeight: '500',
    color: '#0f2137',
    p: '5px 10px',
  },
  discountAmount: {
    fontSize: '14px',
    color: '#10AC84',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: '14px',
    color: '#0f2137',
    mb: 0,
    as: 'span',
  },
};

export default BannerSection;
